import React from 'react';

export const Position = ({ item }) => {
  return (
    <div className="w-full flex flex-col items-start justify-center">
      <p className="font-baloo text-s4tk-blue text-2xl mb-2">{item.title}</p>
      <p className="font-baloo text-black text-xl pl-2">{item.description}</p>
    </div>
  );
};

export default Position;
