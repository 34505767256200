import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Header from '../components/Header';
import S4TKInternshipContent from '../components/About/S4TKInternshipContent';

export const S4TKInternshipPageTemplate = ({
  title,
  header,
  positions,
  cta
}) => (
  <div className="w-full flex flex-col bg-s4tk-blue">
    <Header title={title} />
    <S4TKInternshipContent header={header} positions={positions} cta={cta} />
  </div>
);

const S4TKInternshipPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <S4TKInternshipPageTemplate
        title={frontmatter.title}
        header={frontmatter.header}
        positions={frontmatter.positions}
        cta={frontmatter.cta}
      />
    </Layout>
  );
};

export default S4TKInternshipPage;

export const s4tkInternshipPageQuery = graphql`
  query S4TKInternshipPageTemplate {
    markdownRemark(
      frontmatter: { templateKey: { eq: "s4tk-internship-page" } }
    ) {
      frontmatter {
        title
        header
        positions {
          header
          items {
            title
            description
          }
        }
        cta {
          title
          icon {
            prefix
            title
          }
          url
        }
      }
    }
  }
`;
