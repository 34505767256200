import React from 'react';
import Section, { SubSection } from '../Section';
import Position from './Position';
import SmallLink from '../SmallLink';

export const S4TKInternshipContent = ({ header, positions, cta }) => {
  return (
    <Section>
      <p className="font-baloo mb-4 font-semibold text-s4tk-blue text-3xl text-left sm:text-justify px-0 md:px-4 lg:px-8">
        {header}
      </p>
      <SubSection>
        <p className="font-baloo mb-4 self-center sm:self-start font-semibold text-s4tk-blue text-3xl text-left sm:text-justify">
          {positions.header}
        </p>
        <div className="space-y-6 px-4 mb-8">
          {positions.items.map((item, index) => (
            <Position key={index} item={item} />
          ))}
        </div>
        <div className="w-full flex items-center justify-center">
          <SmallLink item={cta} />
        </div>
      </SubSection>
    </Section>
  );
};

export default S4TKInternshipContent;
